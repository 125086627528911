<template>
	<v-container grid-list-xs>
		<v-data-table v-if="!edit" :loading="loading" :headers="headers" :items="settingsObj" hide-default-footer class="elevation-1">
			<template v-slot:item.bankTransfer="{ item }">
				<v-icon v-if="item.bankTransfer" color="accent">mdi-checkbox-marked</v-icon>
				<v-icon v-else>mdi-close-box</v-icon>
			</template>
			<template v-slot:item.action="{ item }">
				<v-btn icon :disabled="loading" @click="editSettings(item)">
					<v-icon>edit</v-icon>
				</v-btn>
			</template>
		</v-data-table>

		<simple-dialog v-model="dialog" title="Save Confirm">
			<p>Are you sure you want to save these changes?</p>
			<v-flex xs12 class="text-xs-right" slot="button-container">
				<v-btn text @click="dialog = false">Cancel</v-btn>
				<v-btn
					color="accent"
					@click="
						dialog = false;
						onSettingsSave();
					"
					>Agree</v-btn
				>
			</v-flex>
		</simple-dialog>
		<v-layout row wrap v-if="!loading && edit">
			<v-flex xs12 sm6 md3>
				<v-flex xs12>
					<v-select
						:items="allowedAccount"
						v-model="transferAcc"
						item-text="name"
						item-value="id"
						label="Auto Transfer Safe Drops To"
						clearable
						clear-icon="mdi-trash-can-outline"
					></v-select>
				</v-flex>
				<v-flex xs12>
					<v-select
						:items="allowedAccount"
						v-model="settlementAcc"
						item-text="name"
						item-value="id"
						label="Instant Settle Payments To"
						clearable
						clear-icon="mdi-trash-can-outline"
					></v-select>
				</v-flex>
				<v-flex xs12>
					<v-text-field
						ref="refValidator"
						label="Transfer Reference Validation"
						v-model="referenceValidation"
						:rules="[validationsRules]"
					></v-text-field>
				</v-flex>
				<v-flex xs12>
					<v-checkbox label="Allow Bank Transfers" v-model="allowBankTransfers"></v-checkbox>
				</v-flex>
				<v-flex xs12 class="text-xs-right">
					<v-btn color="accent" @click="dialog = !dialog">Save</v-btn>
					<v-btn text @click="cancelUpdate">Cancel</v-btn>
				</v-flex>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import Vue from "vue";
import SimpleDialog from "@/components/SimpleDialog.vue";

//const settingsModule = namespace("organisations/settings");
//const accountsModule = namespace("organisations/accounts");

export default Vue.extend({
	name: "TransactionSettings",

	components: { SimpleDialog },

	computed: {
		transactionSettings: function() {
			return this.$store.state.organisations.settings.transactionSettings;
		},
		transactionPermissions: function() {
			return this.$store.state.organisations.settings.transactionPermissions;
		},
		loading: function() {
			return this.$store.state.organisations.settings.loading;
		},
		accountsByType: function() {
			return this.$store.state.organisations.accounts.accountsByType;
		}
	},

	watch: {
		accountsByType: {
			deep: true,
			immediate: true,
			handler() {
				this.allowedAccount = this.accountsByType.transfer.filter(item => {
					return item.methodKey !== "system" ? item : null;
				});
			}
		},
		transactionSettings: {
			deep: true,
			immediate: true,
			handler(val) {
				if (val) {
					this.transferAcc = val.topupAutoTransferAccountId;
					this.settlementAcc = val.instantSettlementAccountId;
					this.referenceValidation = val.transferReferenceValidationString;
				}
			}
		},
		transactionPermissions: {
			deep: true,
			immediate: true,
			handler(val) {
				if (val) {
					this.allowBankTransfers = val.depositIntoBankAccount;

					let transfer = this.accountsByType.transfer.filter(item => {
						return item.id === this.transferAcc ? item.name : null;
					});

					let settlement = this.accountsByType.transfer.filter(item => {
						return item.id === this.settlementAcc ? item.name : null;
					});

					this.settingsObj = [
						{
							transfer: transfer.length > 0 ? transfer[0].name : "-",
							settlement: settlement.length > 0 ? settlement[0].name : "-",
							reference: this.referenceValidation ? this.referenceValidation : "-",
							bankTransfer: this.allowBankTransfers
						}
					];
				}
			}
		}
	},

	data: function() {
		return {
			transferAcc: null,
			settlementAcc: null,
			allowBankTransfers: false,
			referenceValidation: null,
			dialog: false,
			edit: false,

			settingsObj: [{ transfer: "", settlement: "", reference: "", bankTransfer: false }],

			allowedAccount: [],

			headers: [
				{
					text: "Auto Transfer Safe Drops To",
					value: "transfer"
				},
				{
					text: "Instant Settle Payments To",
					value: "settlement"
				},
				{
					text: "Transfer Reference Validation",
					value: "reference"
				},
				{
					text: "Allow Bank Transfers",
					value: "bankTransfer",
					align: "center"
				},
				{
					text: "Action",
					value: "action",
					align: "center"
				}
			]
		};
	},

	created: function() {
		this.$store.dispatch("organisations/settings/fetchTransactionSettings");
		this.$store.dispatch("organisations/accounts/fetchAccountsByType", { kind: "deposit", tx_type: "transfer" });
	},

	methods: {
		validationsRules(pattern) {
			if (!pattern) return true;

			let parts = pattern.split("/"),
				regex = pattern,
				options = "";
			if (parts.length > 1) {
				regex = parts[1];
				options = parts[2];
			}
			try {
				new RegExp(regex, options);
				return true;
			} catch (e) {
				return "Invalid Regex";
			}
		},
		onSettingsSave() {
			this.$store.dispatch("organisations/settings/updateTransactionSettings", {
				orgTransactionsSettings: {
					topupAutoTransferAccountId: this.transferAcc,
					instantSettlementAccountId: this.settlementAcc,
					transferReferenceValidationString: this.referenceValidation
				},

				permissions: { depositIntoBankAccount: this.allowBankTransfers }
			});

			this.edit = false;
		},
		editSettings() {
			this.edit = true;
		},
		cancelUpdate() {
			this.edit = false;
			this.$store.dispatch("organisations/settings/fetchTransactionSettings");
		}
	}
});
</script>
