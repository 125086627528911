<template>
	<!-- Special TODO note: We will need to look at this again. There is few improvements do in the generating of the dom -->
	<v-container fill-height fluid>
		<simple-dialog v-model="dialog" title="Save Confirm">
			<p>Are you sure you want to save these changes?</p>
			<v-flex xs12 class="text-xs-right" slot="button-container">
				<v-btn text @click="dialog = false">Cancel</v-btn>
				<v-btn
					color="accent"
					@click="
						dialog = false;
						onFeesSave();
					"
					>Agree</v-btn
				>
			</v-flex>
		</simple-dialog>
		<v-layout column>
			<v-flex xs12>
				<v-card dark :color="isDirty ? 'error' : 'success'" height="20px">
					<v-card-text class="pa-0 text-center white--text">Form Status: {{ isDirty ? "Unsaved" : "Saved" }}</v-card-text>
				</v-card>
			</v-flex>
			<v-flex xs12>
				<v-expansion-panels multiple v-model="expansionValues">
					<template v-for="(item, feeTypeIndex) in feesModel">
						<v-expansion-panel
							v-for="(item, subTypeIndex) in item"
							:key="feeTypeIndex + '-' + subTypeIndex"
							:ref="feeTypeIndex + '-' + subTypeIndex"
						>
							<v-expansion-panel-header>
								<div>
									{{ splitCamelCaseToString(feeTypeIndex) }} -
									<span class="font-weight-bold">{{ splitCamelCaseToString(subTypeIndex) }}</span>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-card color="grey lighten-3" class="pl-4 py-2">
									<v-layout v-for="(item, subTypeIndex1) in item" :key="subTypeIndex1" align-center>
										<template v-if="item.fixed !== undefined">
											<v-flex xs3>
												<div>{{ splitCamelCaseToString(subTypeIndex1) }}</div>
											</v-flex>
											<v-flex xs3 pr-3>
												<S2SCurrencyInput
													label="Fixed"
													v-model="feesModel[feeTypeIndex][subTypeIndex][subTypeIndex1]['fixed']"
													@change="onValChange(`${feeTypeIndex}.${subTypeIndex}.${subTypeIndex1}.fixed`, $event)"
												></S2SCurrencyInput>
											</v-flex>
											<v-flex xs3>
												<S2SCurrencyInput
													label="Percentage"
													v-model.number="feesModel[feeTypeIndex][subTypeIndex][subTypeIndex1]['percentage']"
													@change="onValChange(`${feeTypeIndex}.${subTypeIndex}.${subTypeIndex1}.percentage`, $event)"
													symbol="%"
													:decimalFixed="false"
												></S2SCurrencyInput>
											</v-flex>
										</template>
										<v-flex xs3 v-else-if="subTypeIndex1 === 'fixed'">
											<S2SCurrencyInput
												label="Fixed"
												v-model="feesModel[feeTypeIndex][subTypeIndex]['fixed']"
												@change="onValChange(`${feeTypeIndex}.${subTypeIndex}.fixed`, $event)"
											></S2SCurrencyInput>
										</v-flex>
										<v-flex xs3 v-else-if="subTypeIndex1 === 'percentage'">
											<S2SCurrencyInput
												label="Percentage"
												v-model="feesModel[feeTypeIndex][subTypeIndex]['percentage']"
												@change="onValChange(`${feeTypeIndex}.${subTypeIndex}.percentage`, $event)"
												symbol="%"
												:decimalFixed="false"
											></S2SCurrencyInput>
										</v-flex>
									</v-layout>
								</v-card>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</template>
				</v-expansion-panels>
			</v-flex>
		</v-layout>
		<!-- TODO: We need to remove style -->
		<v-btn color="accent" fixed style="bottom:10px; z-index: 10; right: 50px" @click="dialog = true" :disabled="!isDirty">Save</v-btn>
	</v-container>
</template>

<script>
import Vue from "vue";
import SimpleDialog from "@/components/SimpleDialog.vue";
import * as _ from "lodash";

export default Vue.extend({
	name: "FeesSettings",

	components: { SimpleDialog },

	computed: {
		selectedOrganisation: function() {
			return this.$store.state.organisations.selectedOrganisation;
		},
		fees: function() {
			return this.$store.getters["organisations/settings/fees"];
		}
	},

	watch: {
		fees(fees) {
			this.feesModel = JSON.parse(JSON.stringify(fees));

			this.count = 0;
			for (let model in this.feesModel) {
				for (let subModel in this.feesModel[model]) {
					this.setExpansion(this.feesModel[model][subModel]);
					this.count++;
				}
			}
		}
	},

	data: function() {
		return {
			feesModel: {},
			expansionValues: [],

			putObj: { fees: { marketplaceTransactions: {} } },

			isDirty: false,
			dialog: false,
			allOpen: [],
			count: 0,

			headers: [
				{
					text: "Person",
					value: "person"
				},
				{
					text: "Type",
					value: "type"
				},
				{
					text: "Value",
					value: "value"
				}
			],
			currencyOptions: {
				decimal: ".",
				thousands: " ",
				prefix: "R ",
				precision: 2,
				masked: false
			}
		};
	},

	mounted: function() {
		this.$store.dispatch("organisations/settings/fetchSettings");
	},

	methods: {
		setExpansion(items) {
			for (let subItem in items) {
				if (typeof items[subItem] !== "object") {
					if (items.fixed !== 0 || items.percentage !== 0) {
						this.expansionValues.push(this.count);
					}
				} else if (items[subItem].fixed !== 0 || items[subItem].percentage !== 0) {
					this.expansionValues.push(this.count);
				}
			}
		},
		toggleExpansion(exId) {
			const element = this.$refs[exId];
			element[0].isActive = !element[0].isActive;
		},
		splitCamelCaseToString(val) {
			return (
				val
					// replace underscore with space
					.replace(/_/g, " ")
					// insert a space before all caps
					.replace(/([A-Z])/g, " $1")
					// uppercase the first character
					.replace(/^./, function(str) {
						return str.toUpperCase();
					})
			);
		},
		onFeesSave() {
			this.$store.dispatch("organisations/settings/updateFees", JSON.parse(JSON.stringify(this.putObj)));
			this.isDirty = false;
		},
		onValChange(e, k) {
			this.isDirty = true;
			e = `fees.marketplaceTransactions.` + e;
			const result = this.expand(e, k);
			const marketplaceTransactions = this.putObj;
			this.putObj = _.merge(marketplaceTransactions, result);
		},
		expand(str, value) {
			const items = str.split("."); // split on dot notation
			const output = {}; // prepare an empty object, to fill later
			let ref = output; // keep a reference of the new object

			//  loop through all nodes, except the last one
			for (let i = 0; i < items.length - 1; i++) {
				ref[items[i]] = {}; // create a new element inside the reference
				ref = ref[items[i]]; // shift the reference to the newly created object
			}

			ref[items[items.length - 1]] = value; // apply the final value

			return output; // return the full object
		}
	}
});
</script>
