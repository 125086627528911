<template>
	<v-flex xs12>
		<v-tabs v-model="activeTab" background-color="background" slider-color="accent" fixed-tabs class="elevation-1">
			<v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
			<v-tab-item v-for="tab in tabs" :key="tab">
				<v-card text>
					<v-card-text>
						<sales v-if="tab === 'Sales'"></sales>
						<notifications v-else-if="tab === 'Notifications'"></notifications>
						<transactions v-else-if="tab === 'Transactions'"></transactions>
						<fees v-else-if="tab === 'Fees'"></fees>
					</v-card-text>
				</v-card>
			</v-tab-item>
		</v-tabs>
	</v-flex>
</template>

<script>
import Vue from "vue";
import Sales from "@/views/organisations/settings/Sales.vue";
import Notifications from "@/views/organisations/settings/Notifications.vue";
import Transactions from "@/views/organisations/settings/Transactions.vue";
import Fees from "@/views/organisations/settings/Fees.vue";

export default Vue.extend({
	name: "Settings",

	components: { Sales, Notifications, Transactions, Fees },

	data: function() {
		return {
			activeTab: null,
			tabs: ["Sales", "Notifications", "Transactions", "Fees"]
		};
	}
});
</script>
