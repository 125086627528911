<template>
	<v-container grid-list-xs>
		<v-layout row wrap>
			<v-flex xs12>
				<div class="text-xs-left">Offering</div>
			</v-flex>
			<v-flex xs12 sm6 md3>
				<v-checkbox class="mt-0" color="secondary" label="Payments" v-model="settingsModel.acceptPayments"></v-checkbox>
			</v-flex>
			<v-flex xs12 sm6 md3>
				<v-checkbox class="mt-0" color="secondary" label="Orders" v-model="settingsModel.acceptOrders"></v-checkbox>
			</v-flex>
			<v-flex xs12>
				<div class="text-xs-left">Payment</div>
			</v-flex>
			<v-flex xs12 sm6 md3>
				<v-checkbox class="mt-0" color="secondary" label="Pay upfront" v-model="settingsModel.requirePayUpfront"></v-checkbox>
			</v-flex>
			<v-flex xs12 sm6 md3>
				<v-checkbox class="mt-0" color="secondary" label="Pay on delivery" v-model="settingsModel.acceptPayOnDelivery"></v-checkbox>
			</v-flex>
			<v-flex xs12>
				<div class="text-xs-left">Fulfillment</div>
			</v-flex>
			<v-flex xs12 sm6 md3>
				<v-checkbox class="mt-0" color="secondary" label="Delivery" v-model="settingsModel.acceptDeliveries"></v-checkbox>
			</v-flex>
			<v-flex xs12 sm6 md3>
				<v-checkbox class="mt-0" color="secondary" label="Collection" v-model="settingsModel.acceptCollections"></v-checkbox>
			</v-flex>
		</v-layout>
		<v-card v-if="settingsModel.acceptDeliveries">
			<v-card-title>Delivery Settings</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" sm="4">
						<div class="mb-4">
							<label>Delivery Fee</label><br />
							<div v-if="!setFee" @click="setFee = true" class="delivery-fee primary--text">
								{{ formatCurrency(deliveryFee) }} <v-icon class="float-right">edit</v-icon>
							</div>
							<div v-else>
								<v-text-field :rules="[v => /^\d+(.\d{2})?$/.test(v) || 'Number input only (E.g 5.00)']" v-model="deliveryFee"></v-text-field>
								<v-btn color="accent" @click="updateFee()">Update fee</v-btn>
							</div>
						</div>
						<div class="mb-4">
							<label>Delivery Provider</label><br />
							<v-radio-group v-model="deliveryProvider">
								<v-radio
									color="secondary"
									v-for="provider in providers"
									:key="provider.id"
									:label="provider.name"
									:value="provider.id"
								></v-radio>
							</v-radio-group>
						</div>
						<div class="mb-4">
							<label>Require Boxes</label><br />
							<v-switch
								color="secondary"
								v-model="requireBoxes"
								:label="`${requireBoxes ? 'Yes' : 'No'} `"
								@change="updateRequireBoxes(requireBoxes)"
							></v-switch>
						</div>
					</v-col>
					<v-col cols="12" sm="8">
						<v-text-field
							:rules="[v => /^\d+$/.test(v) || 'Number input only (E.g 50)']"
							v-model="deliveryDistance"
							label="Delivery Distance (km)"
							@blur="saveDeliveryRestrictions()"
						></v-text-field>
						<v-autocomplete
							ref="search"
							v-model="selectedAddress"
							:filter="searchResultFilter"
							label="Delivery Places"
							:items="searchItems"
							return-object
							:search-input.sync="searchText"
							placeholder="eg: Cape Town"
							cy-data="address-search"
							clearable
						>
							<template slot="no-data">
								<v-list v-if="searchText == null || searchText == ''" flat class="pa-2">
									Start searching...
								</v-list>
								<v-list v-else flat class="pa-2"> No address found. </v-list>
							</template>
						</v-autocomplete>
						<div v-if="deliveryPlaces.length">
							<v-list-item v-for="(place, index) of deliveryPlaces" :key="place.name" class="elevation-2">
								<v-list-item-content>
									<v-list-item-title>{{ place.province }}</v-list-item-title>
									<v-list-item-subtitle>{{ getPlaceName(place) }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-btn icon text @click="removePlace(index)"><v-icon>delete</v-icon></v-btn>
								</v-list-item-action>
							</v-list-item>
						</div>
					</v-col>
					<v-col cols="12" v-show="requireBoxes">
						<label>Allowed Box Sizes</label>
						<v-row>
							<v-col cols="12" md="4" v-for="size in boxSizes" :key="size.id">
								<v-checkbox
									color="secondary"
									:label="size.name"
									:messages="size.description"
									:value="size.id"
									v-model="selected[size.id - 1]"
									@change="updateBoxSize(size.id)"
								></v-checkbox>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils";

export default Vue.extend({
	name: "SalesSettings",

	computed: {
		selectedOrganisation: function() {
			return this.$store.state.organisations.selectedOrganisation;
		},

		providers: function() {
			return this.$store.state.organisations.activeOrders.providers;
		},
		boxSizes: function() {
			return this.$store.state.organisations.activeOrders.boxSizes;
		},
		allowedBoxSizes: function() {
			return this.$store.state.organisations.activeOrders.allowedBoxSizes;
		},
		deliverySettings: function() {
			return this.$store.getters["organisations/activeOrders/deliverySettings"];
		},
		deliveryRestrictions: function() {
			return this.$store.getters["organisations/activeOrders/deliveryRestrictions"];
		},
		allowedBoxSizesArray: function() {
			return this.$store.getters["organisations/activeOrders/allowedBoxSizesArray"];
		},

		geoSearchAddresses: function() {
			return this.$store.state.organisations.addresses.geoSearchAddresses;
		},
		searchItems: function() {
			if (this.geoSearchAddresses.length) {
				const result = this.geoSearchAddresses.map(entry => {
					const suburb = entry.Location.Address.County;
					const city = entry.Location.Address.City;
					const streetName = entry.Location.Address.Street;
					const streetNumber = entry.Location.Address.HouseNumber;
					const province = entry.Location.Address.State;
					const postalCode = entry.Location.Address.PostalCode;
					const name = entry.Location.Address.Label;
					const latitude = entry.Location.DisplayPosition.Latitude;
					const longitude = entry.Location.DisplayPosition.Longitude;
					let description = entry.Location.Address.Label;
					description += suburb ? ", " + suburb : "";

					this.latLng = { lat: latitude, lng: longitude };

					return {
						text: description,
						value: { name, country: "South Africa", province, city, suburb, streetName, streetNumber, postalCode, latitude, longitude }
					};
				});

				return result;
			} else {
				return [];
			}
		}
	},

	watch: {
		selectedOrganisation: {
			immediate: true,
			handler() {
				this.settingsModel = this.filterOrg(this.selectedOrganisation);
			}
		},
		settingsModel: {
			deep: true,
			handler(newSettings, oldSettings) {
				this.$store.dispatch("organisations/settings/updateSettings", this.settingsModel);
			}
		},
		searchText: {
			deep: true,
			async handler() {
				if (!this.searchText) return;

				try {
					await this.$store.dispatch("organisations/addresses/searchAddress", this.searchText);
				} catch (error) {
					/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
					console.error(error);
				}
			}
		},
		selectedAddress: {
			deep: true,
			handler(val) {
				const allowedKeys = ["country", "province", "city", "suburb", "street"];

				for (let [key, value] of Object.entries(val.value)) {
					if (!allowedKeys.includes(key)) {
						// simply remove key for backend support
						delete val.value[key];
					}
				}

				this.addressModel = { ...this.addressModel, ...val.value };
				this.deliveryPlaces.push({ ...this.addressModel, ...val.value });

				this.saveDeliveryRestrictions();
			}
		},
		deliveryRestrictions: {
			deep: true,
			immediate: true,
			handler() {
				if (this.deliveryRestrictions.distance && this.deliveryRestrictions.places) {
					if (!this.deliveryDistance) this.deliveryDistance = this.deliveryRestrictions.distance;
					if (!this.deliveryPlaces.length) this.deliveryPlaces = this.deliveryRestrictions.places ? this.deliveryRestrictions.places : [];
				}
			}
		},
		deliverySettings: {
			deep: true,
			immediate: true,
			handler(newSettings, oldSettings) {
				if (oldSettings != null) {
					if (!this.deliveryFee) this.deliveryFee = this.deliverySettings.fee;
					if (!this.deliveryProvider) this.deliveryProvider = this.deliverySettings.provider.id;
					if (!this.requireBoxes) this.requireBoxes = this.deliverySettings.requireBoxes;
				}
			}
		},
		allowedBoxSizesArray: {
			deep: true,
			immediate: true,
			handler(newVal) {
				if (!this.selected.length) this.selected = newVal;
			}
		},
		deliveryProvider: {
			deep: true,
			immediate: true,
			handler(newVal, oldVal) {
				if (oldVal > 0) {
					this.$store.dispatch("organisations/activeOrders/updateDeliveryProvider", {
						fee: this.deliveryFee,
						warehouseId: "",
						provider: { id: newVal }
					});
				}
			}
		}
	},

	data: function() {
		return {
			settingsModel: {},

			setFee: false,
			deliveryFee: 0,
			deliveryDistance: 0,
			deliveryProvider: 0,

			requireBoxes: false,

			// selected sizes
			selected: [],

			latLng: {},

			addressModel: null,
			selectedAddress: null,
			searchText: "",
			deliveryPlaces: []
		};
	},

	created: function() {
		this.$store.dispatch("organisations/activeOrders/fetchDeliverySettings");
		this.$store.dispatch("organisations/activeOrders/fetchDeliveryRestrictions");
		// system providers
		this.$store.dispatch("organisations/activeOrders/fetchProviders");
		// system box sizes
		this.$store.dispatch("organisations/activeOrders/fetchBoxSizes");
		// allowed boxes for org
		this.$store.dispatch("organisations/activeOrders/fetchAllowedBoxSizes");
	},

	mounted: function() {
		this.deliveryFee = this.deliverySettings.fee;
		//this.deliveryProvider = this.deliverySettings.provider.id;
		this.requireBoxes = this.deliverySettings.requireBoxes != null ? this.deliverySettings.requireBoxes : false;
		this.selected = this.allowedBoxSizesArray;
	},

	methods: {
		filterOrg(orgObj) {
			return {
				acceptCollections: orgObj.acceptCollections,
				acceptDeliveries: orgObj.acceptDeliveries,
				acceptOrders: orgObj.acceptOrders,
				acceptPayments: orgObj.acceptPayments,
				acceptPayOnDelivery: orgObj.acceptPayOnDelivery,
				requirePayUpfront: orgObj.requirePayUpfront
			};
		},
		updateFee() {
			this.setFee = false;
			this.updateDeliveryFee(this.deliveryFee);
		},
		updateBoxSize(sizeId) {
			let addSize = this.selected.includes(sizeId) ? true : false;
			if (addSize) {
				// Add
				this.$store.dispatch("organisations/activeOrders/addBoxSizes", sizeId);
			} else {
				// Remove
				this.$store.dispatch("organisations/activeOrders/removeBoxSizes", sizeId);
			}
		},
		formatCurrency(text) {
			return Utils.formatText(text, Utils.TextType.CURRENCY);
		},
		searchResultFilter(item, queryText, itemText) {
			return true;
		},
		removePlace(index) {
			this.deliveryPlaces.splice(index, 1);
			this.saveDeliveryRestrictions();
		},
		getPlaceName(place) {
			let placeName = [];
			const allowedKeys = ["country", "province", "city", "suburb", "street"];

			for (let [key, value] of Object.entries(place)) {
				if (allowedKeys.includes(key) && value != null) {
					placeName.push(place[key]);
				}
			}

			return placeName.join(", ");
		},
		saveDeliveryRestrictions() {
			this.$store.dispatch("organisations/activeOrders/updateDeliveryRestrictions", {
				distance: this.deliveryDistance,
				places: this.deliveryPlaces
			});
		},
		updateRequireBoxes(require) {
			this.$store.dispatch("organisations/activeOrders/updateRequireBoxes", require);
		}
	}
});
</script>
